<template functional>
  <svg
    width="10"
    height="10"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5 0.625C4.08865 0.626075 3.21493 0.988585 2.57051 1.63301C1.92609 2.27743 1.56358 3.15115 1.5625 4.0625C1.56141 4.80726 1.80469 5.53181 2.255 6.125C2.255 6.125 2.34875 6.24844 2.36407 6.26625L5 9.375L7.63719 6.26469C7.65094 6.24813 7.745 6.125 7.745 6.125L7.74532 6.12406C8.19541 5.53113 8.43857 4.80692 8.4375 4.0625C8.43643 3.15115 8.07392 2.27743 7.4295 1.63301C6.78507 0.988585 5.91136 0.626075 5 0.625V0.625ZM5 5.3125C4.75278 5.3125 4.5111 5.23919 4.30554 5.10184C4.09998 4.96449 3.93976 4.76926 3.84515 4.54085C3.75054 4.31245 3.72579 4.06111 3.77402 3.81864C3.82225 3.57616 3.9413 3.35343 4.11612 3.17862C4.29094 3.0038 4.51366 2.88475 4.75614 2.83652C4.99862 2.78829 5.24995 2.81304 5.47836 2.90765C5.70677 3.00226 5.90199 3.16248 6.03934 3.36804C6.17669 3.5736 6.25 3.81527 6.25 4.0625C6.24959 4.39389 6.11776 4.7116 5.88343 4.94593C5.6491 5.18026 5.3314 5.31209 5 5.3125V5.3125Z"
      fill="currentColor"/>
  </svg>
</template>

<script>
export default {
  name: 'IconPin'
}
</script>

<style>
</style>
